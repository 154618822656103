<template>
    <common-part title="修改密码">
        <el-form ref="form" label-position="top" label-width="80px" :model="form" :rules="rules">
            <el-form-item label="手机号" prop="phone">
                <el-input name="phone" v-model="form.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="smsCode">
                <el-input name="smsCode" placeholder="请输入验证码" class="sms-input" v-model="form.smsCode">
                    <el-button class="sms-btn" slot="append" :disabled="smsParam.disabled"
                               :loading="smsParam.loading" @click="getCode">{{smsParam.disabled ? `${smsParam.countdown}秒` : '获取验证码'}}</el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input name="password" v-model="form.password" type="password" placeholder="请输入密码" show-password></el-input>
            </el-form-item>
            <el-form-item label="重复密码" prop="confirmPassword">
                <el-input name="confirmPassword" v-model="form.confirmPassword" type="password" placeholder="请再次输入密码" show-password></el-input>
            </el-form-item>
            <div  class="tips">注：密码由6-8位数字、大小写字母组成</div>

            <div class="login-btn">
                <el-button type="primary"  @click="submit" :loading="loading">确定</el-button>
                <div class="cb-login" @click="$router.push('/login')">返回登录页</div>
            </div>
        </el-form>
    </common-part>
</template>

<script>
    import CommonPart from './CommonPart'
    export default {
        components: {CommonPart},
        data() {return {
            visitId: new Date().getTime(),
            pageId: 'find',
            pageName: '找回密码',

            loading: false,
            form: {
                phone: '',
                smsCode:'',
                password: '',
                confirmPassword: '',
            },
            // 验证码相关参数
            smsParam: {
                disabled: false,
                loading: false,
                countdown: 0,
            },
            rules: {
                phone: [
                    { required: true, message: '请输入手机号', trigger: ['blur', 'change'] },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: ['blur', 'change'] },
                ],
                smsCode: [
                    { required: true, message: '请输入验证码', trigger: ['blur', 'change'] },
                ],
                confirmPassword: [
                    { required: true, message: '请再次输入密码', trigger: ['blur', 'change'] },
                ],
            },
        }},
        created() {
            document.onkeydown = () => {
                if(window.event.keyCode === 13){
                    this.submit()
                }
            }

            this.$help.socket.send(this)
        },
        destroyed() {
            document.onkeydown = undefined
        },
        methods: {
            getCode() {
                if (!this.form.phone) {
                    this.$store.commit('error', '请输入手机号')
                    return null;
                }
                this.smsParam.loading = true;
                this.$api.post('sys/sms/sendSms', {
                    smsType: 'password',
                    phone: this.form.phone,
                })
                    .then(res => {
                        if (res.code === this.$variable.SUCCESS_CODE) {
                            // 倒计时
                            this.smsParam.disabled = true;
                            this.smsParam.countdown = 60;
                            const timer = setInterval(() => {
                                this.smsParam.countdown--;
                                if (this.smsParam.countdown === 0) {
                                    this.smsParam.disabled = false;
                                    clearInterval(timer);
                                }
                            }, 1000)
                        } else {
                            this.smsParam.disabled = false;
                            this.$store.commit('error', res.message)
                        }
                    })
                    .finally(() => {
                        this.smsParam.loading = false;
                    })
            },
            submit() {
                if (!(/^[0-9A-Za-z]{6,8}$/.test(this.form.password))) {
                    this.$store.commit('error', '密码由6-8位数字、大小写字母组成')
                    return false
                }
                this.$api.post('sys/sysUser/findPassword', this.form)
                    .then(res => {
                        if (res.code === this.$variable.SUCCESS_CODE) {
                            this.$store.commit('success', '操作成功')
                            this.$router.push('/login')
                        } else {
                            this.$store.commit('error', res.message)
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
        }
    }
</script>